import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

ResendTimer.propTypes = {
  onTimerIsEnd: PropTypes.func,
};

function ResendTimer(props) {
  const [seconds, setSeconds] = useState(120);
  useEffect(() => {
    const interval = setInterval(() => setSeconds(prev => prev - 1), 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      props.onTimerIsEnd();
    }
  }, [seconds]);

  const minutes = Math.floor(seconds / 60);
  const sec = seconds % 60;

  return (
    <strong style={{ fontSize: "1.5rem", marginRight: "2rem" }}>
      0{minutes}:{sec < 10 && 0}
      {sec}
    </strong>
  );
}

export default ResendTimer;
